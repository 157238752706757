var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "event-board", staticStyle: { "text-align": "center" } },
        [
          _c("div", { staticClass: "open-key-detail-202405" }, [
            _c("div", { staticClass: "number-acquired number-box" }, [
              _vm._v(
                " " + _vm._s(_vm.isLogOutMember ? 0 : _vm.takeKeyCount) + " "
              ),
            ]),
            _c("div", { staticClass: "number-openable number-box" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isLogOutMember
                      ? 0
                      : parseInt(_vm.takeKeyCount / 3) - _vm.openBoxCount
                  ) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "number-open number-box" }, [
              _vm._v(
                " " + _vm._s(_vm.isLogOutMember ? 0 : _vm.openBoxCount) + " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "btn-open-event" }, [
            _c("a", { attrs: { title: "럭키박스 오픈" } }, [
              _vm.isLogOutMember
                ? _c("img", {
                    staticStyle: { cursor: "default" },
                    attrs: {
                      src: require("@/assets/images/events/event240415/btn-detail-disabled.png"),
                      alt: "럭키박스 오픈하기 비활성화",
                    },
                  })
                : _vm.openBoxCount == 3
                ? _c("img", {
                    staticStyle: { cursor: "default" },
                    attrs: {
                      src: require("@/assets/images/events/event240415/btn-detail-completion.png"),
                      alt: "오픈완료",
                    },
                  })
                : _vm.takeKeyCount < 3 ||
                  parseInt(_vm.takeKeyCount / 3) <= _vm.openBoxCount ||
                  _vm.checkDisableDate(_vm.startDate, _vm.endDate)
                ? _c("img", {
                    staticStyle: { cursor: "default" },
                    attrs: {
                      src: require("@/assets/images/events/event240415/btn-detail-disabled.png"),
                      alt: "럭키박스 오픈하기 비활성화",
                    },
                  })
                : _c("img", {
                    style: { cursor: _vm.isProcessing ? "default" : "pointer" },
                    attrs: {
                      src: require("@/assets/images/events/event240415/btn-detail-open.png"),
                      alt: "럭키박스 오픈하기",
                    },
                    on: {
                      click: function ($event) {
                        _vm.isProcessing ? void 0 : _vm.openLuckyBox()
                      },
                    },
                  }),
            ]),
          ]),
          _c("div", { staticClass: "btn-go-banner" }, [
            _c(
              "a",
              {
                attrs: { title: "행운의 열쇠 획득하기" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      name: "app-medical-trend-main",
                      query: { entry: 122 },
                    })
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/events/event240415/btn-event-lucky-go.png"),
                    alt: "행운의 열쇠 획득하기",
                  },
                }),
              ]
            ),
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/events/event240415/detail-05.jpg"),
              alt: "열려라 럭키박스 이벤트 상세",
            },
          }),
        ]
      ),
      _c(_vm.popupComponent, {
        ref: "preProcessPopup",
        tag: "component",
        attrs: { "event-id": _vm.eventId },
        on: { close: _vm.closePreProcessPopup },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }